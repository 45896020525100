import { Injectable } from '@angular/core';
import { RESTService } from '../../services/rest.service';
import { RESTModel } from '../../models/rest.model';

@Injectable()
export class S3ServiceService extends RESTService<Urls>{
  public newResource(data: any): Urls {
    return new Urls(this, data);
  }

  public getSignedUrls(
    projectId,
    studyId,
    fileNames: File[],
  ): Promise<Urls> {
    this.setApiEndpoint(
      '/files/getSignedUrl?' +
      `projectId=${projectId}` +
      `&studyId=${studyId}`
    );
    return this.create({filesMetadata: fileNames});
  }

}


export class Urls extends RESTModel {
  public urls: File[] = [];

  constructor(service: RESTService<Urls>, data: any) {
    super(service, data);
  }

}
